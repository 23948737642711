var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.image
    ? _c(
        "v-col",
        { staticClass: "d-flex" },
        [
          _c(
            "v-card",
            {
              staticClass: "rounded-lg d-flex justify-center pa-4 pa-md-8",
              attrs: { color: "transparent", elevation: "0", width: "100%" }
            },
            [
              _c("v-img", {
                staticClass: "imgwidth",
                attrs: { src: _vm.image, contain: "" }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }