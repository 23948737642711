var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.labels && _vm.values
    ? _c("v-row", { attrs: { align: "center", justify: "center" } }, [
        _c("div", { staticClass: "chart" }, [
          _c("canvas", { attrs: { id: "myChart" } })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }