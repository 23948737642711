<template>
  <v-row align="center" justify="center" v-if="labels && values">
    <div class="chart">
      <canvas id="myChart"></canvas>
    </div>
  </v-row>
</template>

<script>
import { Chart, registerables } from "chart.js"
Chart.register(...registerables)
Chart.defaults.font.size = 16
import ChartDataLabels from "chartjs-plugin-datalabels"

export default {
  name: "GameDoughnutChart",
  props: {
    labels: {
      default: () => [],
      type: Array,
    },
    values: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      myChart: null,
      chartOptions: {
        responsive: true,
        scale: {
          ticks: {
            precision: 0,
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "bottom",
          },
          // title: {
          //   display: true,
          //   text: "Answer Split",
          // },
          datalabels: {
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0 // or >= 1 or ...
            },
            // formatter: (value, ctx) => {
            //   let sum = 0
            //   let dataArr = ctx.chart.data.datasets[0].data
            //   dataArr.map((data) => {
            //     sum += data
            //   })
            //   let percentage = ((value * 100) / sum).toFixed(0) + "%"
            //   return percentage
            // },
            color: "#ffffff",
          },
        },
        layout: {
          padding: 20,
        },
        animation: {
          duration: 2000,
          rotation: 45,
          animateRotate: true,
          animateScale: true,
          render: true,
        },
      },
    }
  },

  mounted() {
    this.renderChart()
  },

  updated() {
    this.renderChart()
  },

  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: this.values,
            backgroundColor: ["#77CEFF", "#0079AF", "#123E6B", "#86a5d9", "#A5C8ED", "#574bb6"],
          },
        ],
      }
    },

    chartConfig() {
      return {
        type: "doughnut",
        data: this.chartData,
        options: this.chartOptions,
        plugins: [ChartDataLabels],
      }
    },
  },
  methods: {
    renderChart() {
      if (this.myChart) {
        this.myChart.destroy()
      }
      this.myChart = new Chart(document.getElementById("myChart"), this.chartConfig)
    },
  },
}
</script>

<style>
.chart {
  width: 600px;
}
</style>
