<template>
  <div class="chart">
    <canvas id="myChart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js"
Chart.register(...registerables)
Chart.defaults.font.size = 20
import ChartDataLabels from "chartjs-plugin-datalabels"

export default {
  name: "GameBarChart",
  props: {
    labels: {
      default: () => [],
      type: Array,
    },
    values: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      myChart: null,
      chartOptions: {
        responsive: true,
        scale: {
          ticks: {
            precision: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: "#ffffff",
          },
        },
        // layout: {
        //   padding: 50,
        // },
        animation: {
          duration: 2000,
          animateScale: true,
          render: true,
        },
      },
    }
  },

  mounted() {
    this.renderChart()
  },

  updated() {
    this.renderChart()
  },

  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: this.values,
            backgroundColor: ["#4CAF50", "#FF5252", "#2196F3", "#FF8F00", "#244166", "#4a70a3"],
          },
        ],
      }
    },

    chartConfig() {
      return {
        type: "bar",
        data: this.chartData,
        options: this.chartOptions,
        plugins: [ChartDataLabels],
      }
    },
  },
  methods: {
    renderChart() {
      if (this.myChart) {
        this.myChart.destroy()
      }
      this.myChart = new Chart(document.getElementById("myChart"), this.chartConfig)
    },
  },
}
</script>

<style>
.chart {
  width: 100%;
  height: 100%;
}
</style>
