<template>
  <div v-if="participantList">
    <GameDoughnutChart :labels="labels" :values="values" v-if="answerstats && $vuetify.breakpoint.xs" />
    <GameBarChart :labels="labels" :values="values" v-if="answerstats && $vuetify.breakpoint.smAndUp" />
  </div>
</template>

<script>
import { mapState } from "vuex"
import GameDoughnutChart from "@/components/game/common/GameDoughnutChart.vue"
import GameBarChart from "@/components/game/common/GameBarChart.vue"
export default {
  name: "GameAnswerStats",
  props: {
    questionid: {
      default: () => null,
      type: Number,
    },
  },
  components: {
    GameDoughnutChart,
    GameBarChart,
  },
  computed: {
    ...mapState({
      participantList: (state) => state.game.participantList,
      gameSession: (state) => state.game.gameSession,
    }),
    answerstats() {
      let stats = { "No Answer": 0 }
      this.participantList.forEach((participant) => {
        if (participant.details[this.gameSession.index]) {
          let answer = participant.details[this.gameSession.index].chosenanswer
          if (stats[answer]) {
            stats[answer] += 1
          } else {
            stats[answer] = 1
          }
        } else {
          stats["No Answer"] += 1
        }
      })
      return stats
    },
    labels() {
      return Object.keys(this.answerstats)
    },
    values() {
      return Object.values(this.answerstats)
    },
  },
}
</script>
