<template>
  <v-col class="d-flex" v-if="image">
    <v-card color="transparent" elevation="0" class="rounded-lg d-flex justify-center pa-4 pa-md-8" width="100%">
      <v-img :src="image" class="imgwidth" contain></v-img>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "GameQuestionImage",
  props: {
    image: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.imgwidth {
  width: 100%;
  max-width: 200px;
  height: auto;
}
</style>
