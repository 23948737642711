var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.participantList
    ? _c(
        "div",
        [
          _vm.answerstats && _vm.$vuetify.breakpoint.xs
            ? _c("GameDoughnutChart", {
                attrs: { labels: _vm.labels, values: _vm.values }
              })
            : _vm._e(),
          _vm.answerstats && _vm.$vuetify.breakpoint.smAndUp
            ? _c("GameBarChart", {
                attrs: { labels: _vm.labels, values: _vm.values }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }