var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c(
        "v-row",
        {
          staticClass: "d-flex question-section justify-center align-stretch",
          attrs: { dense: "" }
        },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "pa-4 flex-grow-1 d-flex justify-center rounded-lg",
                  attrs: { color: "transparent", elevation: "0" }
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "font-weight-bold justify-center secondary--text questiontitle"
                    },
                    [_vm._v(" " + _vm._s(_vm.question.question) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._t("game-question-image", null, { image: _vm.question.imagelink })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }