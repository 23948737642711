<template>
  <v-row class="d-flex question-section justify-center align-stretch" dense v-if="question">
    <v-col cols="12" class="d-flex">
      <v-card color="transparent" elevation="0" class="pa-4 flex-grow-1 d-flex justify-center rounded-lg">
        <v-card-title class="font-weight-bold justify-center secondary--text questiontitle">
          {{ question.question }}
        </v-card-title>
      </v-card>
    </v-col>
    <slot name="game-question-image" :image="question.imagelink"></slot>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "GameQuestion",
  data() {
    return {
      answercolor: ["success", "error", "info", "warning", "secondary", "accent"],
    }
  },
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
  },
}
</script>

<style lang="scss" scoped>
.question-section {
  .questiontitle {
    font-size: clamp(1.2rem, 2.2vmax, 4rem);
    word-break: normal;
    line-height: clamp(1.2rem, 2.2vmax, 4rem);
  }
}
.v-card {
  // border: 1px solid var(--v-secondary-base);
  // background-color: #ffdfad;
}
</style>
